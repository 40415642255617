import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../store/actions/change'


import Result from './Result';
import { useTranslation } from 'react-i18next';
import { useLocation,  } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Avatar } from '@material-ui/core';
import { LockReset } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    avatar: {
        "& *": {
            fontSize: "7rem"
        }
    },
    mainContainer: {
        paddingTop: "2rem",
        paddingBottom: "2rem",
    },
    username: {
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        textAlign: "center",
    },
    child: {
        marginTop: "2rem",
    },
    submitContainer: {
        paddingBottom: "1rem",
    },
}));

const Inputs = (props) => {

    const { t } = useTranslation();
    const [ verifyPassword, setVerifyPassword ] = props.verifyState;
    const [ password, setPassword ] = props.passwordState;

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
                <TextField
                autoFocus={true}
                id="password"
                label={t("Password")}
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value) }
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                id="verifyPassword"
                label={t("Verify password")}
                type="password"
                value={verifyPassword}
                onChange={(event) => setVerifyPassword(event.target.value) }
                fullWidth />
            </Grid>
        </Grid>
    )
}

const Change = (props) => {
     
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const passwordState = useState('');
    const verifyState = useState('');

    const { search } = location;
    const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
    console.debug("ChangePassword has been started with URL parameters: ", urlParams); 

    const { state } = props;
    const { change } = state;
    const { result } = change;

    console.debug("Change result is: ", result)

    const handleSubmit = () => {
        
        const [ password ] = passwordState;
        const [ verifyPassword ] = verifyState;

        console.debug("Change password. password: " + password + ", verify password: " + verifyPassword)

        if (password === null || password === "" || password !== verifyPassword) {
            const msg = "passwords-dont-match";
            console.debug(t(msg))
            props.setResult(Error(msg));
            return;
        }
        
        const authUsername = urlParams.user;
        const authPassword = urlParams.hash;
        console.debug("Change password. auth username: " + authUsername + ", auth password: " + authPassword)
        
        const credentials = {
            username: authUsername,
            password: authPassword,
        }

        props.start(credentials, password);
    }
    const handleResultClose = () => props.setResult(null);

    const child = !result ? <Inputs verifyState={verifyState} passwordState={passwordState} /> : <Result result={result} onClose={handleResultClose} />

    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} container justifyContent='center' alignItems='center' direction="column">
                <Avatar className={classes.avatar} sx={{ height: '64px', width: '64px', background: "#de7c34" }}><LockReset /></Avatar>
                <h2>{t("Change password")}</h2>
            </Grid>
            <Grid item xs={12} className={classes.mainContainer}>
                <Typography>
                    {t("change-password-text")}
                </Typography>
                <Typography variant='h6' className={classes.username}>
                    {urlParams.user}
                </Typography>
                {child}
            </Grid>
            <Grid item xs={12} container justifyContent='end' alignItems='center' className={classes.submitContainer}>
                    <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="outlined">
                        {t("Change your password")}
                    </Button>
             </Grid>
        </Grid>
    );
}

const mapDispatchToProps = dispatch => {
    return {
      start: (credentials, newPassword) => dispatch(actions.start(credentials, newPassword)),
      setResult: (value) => dispatch(actions.setResult(value)),
    }
  }
  
  function mapStateToProps(state) {
    return {
      state: state,
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Change)