// React imports
import React from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { Grid, Link, Paper } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

//i18n
import { useTranslation } from 'react-i18next';

// Own components
import Change from './Change';
import Send from './Send'

// Other
import * as routes from '../other/routes'
import LanguageSelector from './LanguageSelector';

const useStyles = makeStyles( (theme) => (
  {
    root: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxSizing: "border-box",
      padding: "1rem",
      minWidth: '320px',
      maxWidth: '500px',
    },
    footer: {
      borderTop: "solid 1px #dfdfdf",
      paddingTop: "1rem",
    },
    envInfo: {
      position: "absolute",
      bottom: "0",
      right: "0",
      padding: "0.5rem",
      color: "#143659",
    }
  }
))

const Layout = (props) => {
  
    const { t } = useTranslation()  
    const classes = useStyles()
    const ITRACK_LOGIN_URL = process.env.REACT_APP_ITRACK_LOGIN_URL
    const handleGoToItrack = () => window.location.replace(ITRACK_LOGIN_URL);

    return (
      <>
        <div className={classes.root}>
          <Paper elevation={10} className={classes.paper}>
              <Routes>
                  <Route exact path={routes.ROUTE_CHANGE} element={<Change />} />
                  <Route exact path={routes.ROUTE_SEND} element={<Send />} />
                  <Route path="/*" element={<Send />} />
              </Routes>
              <Grid container justifyContent='start' alignItems='center' className={classes.footer}>
                  <Grid item xs={12} sm={9} container justifyContent='start' alignItems='center'>
                      <Link
                      to="#"
                      href="#0"
                      underline="none"
                        onClick={handleGoToItrack}>{t("Go to iTrack sign in")}</Link>
                  </Grid>
                  <Grid item xs={12} sm={3} container justifyContent='end' alignItems='center'>
                      <LanguageSelector />
                  </Grid>
              </Grid>
          </Paper>
        </div>
        <div className={classes.envInfo}>
          <span>{process.env.REACT_APP_ENV_ID || "???"}</span>
        </div>
      </>
    )
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

function mapStateToProps(state) {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)