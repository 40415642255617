import { makeStyles } from "@mui/styles";
import Collector from "./components/Collector";
import Layout from "./components/Layout";

import './i18n/i18n' // initialization

const useStyles = makeStyles( (theme) => (
  {
    "@global": {
      html: {
        margin: 0,
        padding: 0,
        fontFamily: "'Exo', sans-serif",
      },
      body: {
        backgroundColor: "#b2d2dd",
      }
    },
    root: {

    }
  }
))

const App = () => {
  const classes = useStyles()
  return (
      <Collector>
        <Layout className={classes.root}/>
      </Collector>
  )
}

export default App;
