// State
const update = (state, updatedValues) => {
    return {
        ...state,
        ...updatedValues
    }
}

export {
    // general helpers
	update,
}