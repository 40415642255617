import { takeEvery, putResolve, call } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as actions from '../actions/change'
import * as repo from '../../repository/change'

function* changeStart(action) {
    let result = null;
    try {
        const { payload } = action
        const { credentials, newPassword } = payload

        console.debug("Change password start: ", credentials, " newPassword: ", newPassword)

        const params = {
            credentials: credentials,
            newPassword: newPassword,
        }

        result = yield call(repo.change, params) //NODE: call is blocking!
        yield putResolve(actions.setResult(result))

    } catch (exp) {
        console.warn("Error while sending change password request: ", exp);
        yield putResolve(actions.setResult(exp))
    }

    console.debug("Change password request finished. Result: ", result)
    yield putResolve(actions.finished())
}


export function* saga() {
    yield takeEvery(actionTypes.CHANGE_START, changeStart)
}
