// Action Types import
import * as actionTypes from '../actions/actionTypes'

// Other imports
import { update } from '../../other/common.js'

const initialState = {    
    inProgress: false,
    result: null,
}

const change = (state = initialState, action) => {

    switch (action.type) {

        //password change
        case actionTypes.CHANGE_START: return update(state, {inProgress: true, result: null})
        case actionTypes.CHANGE_FINISHED: return update(state, {inProgress: false})
        case actionTypes.CHANGE_SET_RESULT: return update(state, {result: action.payload})
        
        default: return state
    }
}

export default change