import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware } from 'redux'

import rootReducer from './reducers/root'
import { createStore } from 'redux'


const initStore = () => {
    
    // Create saga and middleware
    const saga = createSagaMiddleware()
    const middlewares = [saga]

    // Add middlewares
    middlewares.push(logger)

    // Create the store, add reducers and middlewares
    const store = createStore(rootReducer, applyMiddleware(...middlewares))   

    return { store, saga }
}

export default initStore