// Root
export const STORE_CREATED = "STORE_CREATED"

// Reset password
export const SEND_START = "SEND_START"
export const SEND_FINISHED = "END_FINISHED"
export const SEND_SET_RESULT = "SEND_SET_RESULT"

// Change password
export const CHANGE_START = "CHANGE_START"
export const CHANGE_FINISHED = "CHANGE_FINISHED"
export const CHANGE_SET_RESULT = "CHANGE_SET_RESULT"

// Collector
export const COLLECTOR_SET_I18N = "COLLECTOR_SET_I18N"
export const COLLECTOR_SET_LOCATION = "COLLECTOR_SET_LOCATION"