import { takeEvery, putResolve, call, select } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as actions from '../actions/send'
import * as repo from '../../repository/send'

function* sendStart(action) {
    let result = null;
    try {
        const { payload } = action
        const { desiredEmail, desiredUser } = payload
        const { collector} = yield select()
        const { i18n } = collector
        const lang = i18n.language === "enUS" ? "en" : i18n.language ?? "en"

        console.debug("Sending password reset request for user: ", desiredUser, " and email: ", desiredEmail, " with language: ", lang)


        const params = {
            lang: lang,
            desiredEmail: desiredEmail,
            desiredUser: desiredUser,
        }

        result = yield call(repo.send, params) //NODE: call is blocking!
        yield putResolve(actions.setResult(result))

    } catch (exp) {
        console.warn("Error while sending password reset request: ", exp);
        yield putResolve(actions.setResult(exp))
    }

    console.debug("Password reset request finished. Result: ", result)
    yield putResolve(actions.finished())
}

export function* saga() {
    yield takeEvery(actionTypes.SEND_START, sendStart)
}
