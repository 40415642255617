import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Send a password reset request to the server
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.desiredUser - The name of the user
 * @param {string} params.desiredEmail - The email address of the user
 * @param {string} params.credentials - The username and password
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 *   
 * Example URL:
 * https://m.itrack.hu/ws-rest/api/v1.1/passwordreset/sendreset?desiredUser=foo&desiredEmail=foo@bar.com&lang=hu
 * 
 * This feature has been implemented without documentation. The above URL is the result of reverse engineering of m.itrack.hu
 * The API is not documented, but the implementation is available in the source code of m.itrack.hu (old Pluto developed by Qualysoft)
 */
export const send = (params) => {
    console.debug("Params in 'send' api call: ", params)
    //XXX: This is a POST request with query parameters.
    //The second parameter must be an empty object, otherwise the query parameters will be ignored!
    //I have spent hours to find this out, so I am documenting it here.
    //
    //Read the related StackOverflow question for more details:
    //https://stackoverflow.com/questions/53501185/how-to-post-query-parameters-with-axios
    return axios.post(
        `${BASE_URL}/passwordreset/sendreset`,
        {},
        {
            params: {
                lang: params.lang, //XXX: check if it's necessary or not! The request header also contains the language!
                desiredUser: params.desiredUser,
                desiredEmail: params.desiredEmail,
            },
        }
    )
}