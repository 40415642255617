import { Close } from '@material-ui/icons';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

const Result = (props) => {

    const { result, onClose } = props;

    if (!result) return null;

    const status = result.status;
    const response = result.response;

    if (status === 204) return <SuccessResponse response={response} onClose={onClose} />
    if (response != null) return <ErrorResponse response={response} onClose={onClose} />
    return <GeneralError result={result} onClose={onClose} />
}

const ErrorAlert = (props) => {
  
  const { t } = useTranslation();
  const message = props.message;
  const onClose = props.onClose;

  return (
    <Alert action={
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={onClose}>
        <Close fontSize="inherit" />
      </IconButton>
    }
    severity="error"
    sx={{ mb: 2 }}>
      <AlertTitle>{t("Error")}</AlertTitle>
      {message}
  </Alert>
  );
}

const GeneralError = (props) => {

  const { t } = useTranslation();
  const result = props.result;
  const onClose = props.onClose;
  const displayMessage = result.message ?? "Unknown error!";

  return <ErrorAlert message={t(displayMessage)} onClose={onClose} />
}

const ErrorResponse = (props) => {

  const { response, onClose } = props;
  const data = response.data;
  const { message, localizedMessage } = data;
  const displayMessage = localizedMessage ?? message ?? "Unknown error!";

  return <ErrorAlert message={displayMessage} onClose={onClose} />
}

const SuccessResponse = (props) => {

  const { t } = useTranslation();
  const { onClose } = props;

  return (
      <Alert action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}>
            <Close fontSize="inherit" />
          </IconButton>
        }
        severity="success"
        sx={{ mb: 2 }}>
          <AlertTitle>{t("Success")}</AlertTitle>
          {t("Password reset email sent")}
      </Alert>
  )
}

export default Result;