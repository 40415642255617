// Redux
import { combineReducers } from 'redux'

// Reducer imports
import send from './send'
import change from './change'
import collector from './collector'


// Combine reducers
const rootReducer = combineReducers({
    send: send,
    change: change,
    collector: collector,
})

export default rootReducer