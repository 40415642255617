import { FormControl } from '@material-ui/core';
import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import languages from '../i18n/languages.json';

const LanguageSelector = (props) => {
    
    const { i18n } = useTranslation();
    const keys = Object.keys(languages);

    //languages[k].name is also available and it contains the name of the language in English
    //but we don't use it at the moment
    const items = keys.map((k) => <MenuItem value={k} key={k}>{languages[k].flag}&nbsp;{k.toUpperCase()}</MenuItem>)

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
        console.debug("Change language to: ", event.target.value);
    }

    const lng = i18n.language;
    const res = i18n.resolvedLanguage;
    const current = lng === res ? lng : res;
    console.debug("Current language: ", current, ", Language: ", i18n.language, ", Resolved language: ", i18n.resolvedLanguage)


    return (
        <FormControl>
            <Select
                disableUnderline={true}
                value={current}
                onChange={handleChange}>
                {items}
            </Select>
        </FormControl>
    )
}

export default LanguageSelector