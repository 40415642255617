// Action Types import
import * as actionTypes from '../actions/actionTypes'

// Other imports
import { update } from '../../other/common.js'

const initialState = {    
    i18n: null,
    location: null,
}

const collector = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.COLLECTOR_SET_I18N: return update(state, {i18n: action.payload})
        case actionTypes.COLLECTOR_SET_LOCATION: return update(state, {location: action.payload})
        
        default: return state
    }
}

export default collector