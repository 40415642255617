import { spawn } from 'redux-saga/effects'

import { saga as chnage } from './change'
import { saga as send } from './send'
import { saga as collector } from './collector'

const modules = [
    chnage,
    send,
    collector,
    
]

function* rootSaga() {

    // spawn all modules
    for(let i = 0; i < modules.length; i++) {
        yield spawn(modules[i])
    }

}

export default rootSaga