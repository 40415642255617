import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import * as actions from '../store/actions/collector'

const Collector = (props) => {
 
    const { setI18n, setLocation } = props;
    const { i18n } = useTranslation()
    const location = useLocation()

    useEffect(() => {
        console.debug("Collector has been started with i18n: ", i18n, " location: ", location)
        setI18n(i18n)
        setLocation(location)
    }, [i18n, location, setI18n, setLocation])

    return props.children
}

const mapDispatchToProps = dispatch => {
    return {
      setI18n: (i18n) => dispatch(actions.setI18n(i18n)),
      setLocation: (location) => dispatch(actions.setLocation(location)),
    }
  }
  
  function mapStateToProps(state) {
    return {
      state: state,
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Collector)