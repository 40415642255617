import * as actionTypes from './actionTypes'

//Change password
export const start = (credentials, newPassword) => {
    return {
        type: actionTypes.CHANGE_START,
        payload: {
            credentials: credentials,
            newPassword: newPassword,
        }
    }
}

export const finished = () => {
    return {
        type: actionTypes.CHANGE_FINISHED,
    }
}

export const setResult = (value) => {
    return {
        type: actionTypes.CHANGE_SET_RESULT,
        payload: value,
    }
}